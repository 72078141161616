import { format } from 'date-fns'

interface CouponsRawResponseRecord {
  coupon_code: string // クーポンコード
  coupon_name: string // クーポン名
  description: string // クーポン説明文
  notice_title: string
  notice_description: string
  image_url: string | undefined
  coupon_start_date: string // クーポン有効期限開始日
  coupon_expire_date: string // クーポン有効期限終了日
  max_use_count: number // クーポン使用可能回数 (0 なら無制限)
  use_count: number // 会員がクーポンを使用した回数
  can_use: number // クーポン使用可能かどうか(不可の場合0、可能な場合1)※1
}

export interface CouponsRawResponse {
  status: 0 | 100 // 正常終了=0, トークン有効期限切れ=200
  data_count: number
  per_page: number
  page_no: number
  page_count: number
  can_use_count: number
  coupons: CouponsRawResponseRecord[]
}

export interface CouponMeta {
  dataCount: number
  perPage: number
  pageNo: number
  pageCount: number
  canUseCount: number
}

export interface Coupon {
  couponCode: string // クーポンコード
  couponName: string // クーポン名
  description: string // クーポン説明文
  noticeTitle: string
  noticeDescription: string
  imageUrl: string | undefined
  couponStartDate: string // クーポン有効期限開始日
  couponExpireDate: string // クーポン有効期限終了日
  maxUseCount: number // クーポン使用可能回数 (0 なら無制限)
  useCount: number // 会員がクーポンを使用した回数
  canUse: number // クーポン使用可能かどうか(不可の場合0、可能な場合1)※1
}

export interface CouponsResponse {
  status: 0 | 100 // 正常終了=0, トークン有効期限切れ=200
  meta: CouponMeta
  coupons: Coupon[]
}

export interface CouponsSearchOptions {
  count?: number // ページ当たりの表示件数 未指定の場合全件取得
  page?: number // ページ番号 未指定の場合1
  sort?: number // // ソート(0:日付で降順 1:日付で昇順) 未指定の場合降順
  from?: string // 取得期間開始日(YYYY-MM-DD) 未指定の場合絞らない
  to?: string // 取得期間終了日(YYYY-MM-DD) 未指定の場合絞らない
  id?: number // お知らせID
}

export function parseCouponsSearchOptions(options: CouponsSearchOptions) {
  return {
    count: String(options.count || 100),
    page: String(options.page || 1),
    sort: String(options.sort || 0), // 0:日付で降順 1:日付で昇順
    ...(options.from ? { from: String(options.from) } : undefined),
    ...(options.to ? { to: String(options.to) } : undefined),
    ...(options.id ? { id: String(options.id) } : undefined),
  }
}

export function fromCouponsRawResponse(
  rawResponse: CouponsRawResponse
): CouponsResponse {
  const status = rawResponse.status
  const meta = {
    dataCount: rawResponse.data_count,
    perPage: rawResponse.per_page,
    pageNo: rawResponse.page_no,
    pageCount: rawResponse.page_count,
    canUseCount: rawResponse.can_use_count,
  }
  const coupons = rawResponse.coupons.map((record) => {
    const coupon: Coupon = {
      couponCode: record.coupon_code,
      couponName: record.coupon_name,
      description: record.description,
      noticeTitle: record.notice_title,
      noticeDescription: record.notice_description,
      imageUrl: record.image_url,
      couponStartDate: record.coupon_start_date,
      couponExpireDate: record.coupon_expire_date,
      maxUseCount: record.max_use_count,
      useCount: record.use_count,
      canUse: record.can_use,
    }
    return coupon
  })

  return { status, meta, coupons }
}

function compareCoupons(a: Coupon, b: Coupon): number {
  if (a.couponExpireDate > b.couponExpireDate) return 1
  if (a.couponExpireDate < b.couponExpireDate) return -1
  return 0
}

export function isAvailable(coupon: Coupon) {
  return coupon.canUse === 1
}

export function isUnavailableYet(coupon: Coupon, todayStr: string) {
  // もう↓の2つにカラのデータが入ることはないらしいけど、残す
  if (!coupon.couponStartDate) return false
  if (!coupon.couponExpireDate) return false

  // ある日突然 date が datetime で返ってくるようになっていたのでその対応。もう date だけど保険で datetime でも大丈夫なように残す
  const couponStartDate = coupon.couponStartDate.split(' ')[0]
  const couponExpireDate = coupon.couponExpireDate.split(' ')[0]
  if (couponExpireDate < todayStr) return false // もう有効期限切れ => false
  if (couponStartDate <= todayStr) return false // もう開始済み => false

  return true
}

export function isHidden(coupon: Coupon, todayStr: string) {
  if (!coupon.couponStartDate) return true
  if (!coupon.couponExpireDate) return true

  const couponExpireDate = coupon.couponExpireDate.split(' ')[0]
  if (couponExpireDate < todayStr) return true // もう有効期限切れ => true

  return false
}

export function sortCoupons(coupons: Coupon[] | undefined, today?: Date) {
  if (!coupons) {
    return undefined
  }
  const todayStr = format(today || new Date(), 'yyyy-MM-dd')
  const sortedCoupons = [...coupons]
    .filter((coupon) => !isHidden(coupon, todayStr))
    .sort(compareCoupons)
    .reduce<{
      [K in
        | 'availableCoupons'
        | 'unavailableYetCoupons'
        | 'unavailableCoupons']: Coupon[]
    }>(
      (acc, coupon) => {
        if (isAvailable(coupon)) {
          acc.availableCoupons.push(coupon)
        } else if (isUnavailableYet(coupon, todayStr)) {
          acc.unavailableYetCoupons.push(coupon)
        } else {
          acc.unavailableCoupons.push(coupon)
        }
        return acc
      },
      {
        availableCoupons: [],
        unavailableYetCoupons: [],
        unavailableCoupons: [],
      }
    )
  const { availableCoupons, unavailableYetCoupons, unavailableCoupons } =
    sortedCoupons
  return [...availableCoupons, ...unavailableYetCoupons, ...unavailableCoupons]
}
