import React, { useRef, useEffect } from 'react'
// import JsBarcode from 'jsbarcode'
// node_modules/jsbarcode/bin/JsBarcode // main: bin/JsBarcode.js
import JsBarcode from 'jsbarcode'
// node_modules/jsbarcode/bin/barcodes/CODE128
// @ts-ignore
// import JsBarcode from 'jsbarcode/bin/barcodes/CODE128/CODE128'

// TODO: reduce jsbarcode size - jsbarcode: 57.16 KB

interface BarcodeProps {
  value: string
}

export function Barcode({ value }: BarcodeProps) {
  const style = {
    maxWidth: '100%',
  }
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    JsBarcode(canvasRef.current, value, {
      format: 'CODE128',
      displayValue: false,
      height: 96,
      margin: 0,
      marginLeft: 10,
      marginRight: 10,
    })
  }, [canvasRef, value])

  return <canvas ref={canvasRef} style={style}/>
}
