export type MemberRank = 'guest' | 'general' | 'vip'

type SexT = 0 | 1 | 2 // 性別(0:不明 1:男性 2:女性)
type MemberKindT = 0 | 1 // 会員種別:(0:仮会員 1:本会員 )
type MemberTypeT = 1 | 2 // 会員タイプ:(1:一般 2:VIP) // 旧→会員タイプ:(0:一般 1:VIP)
type EmployeeFlagT = 0 | 1 // 従業員フラグ:(0:非従業員 1:従業員)

type GenderT = 'other' | 'male' | 'female'

export interface FetchMemberRawResponse {
  status: 0 | 100 // 正常終了=0, トークン有効期限切れ=100
  member: {
    id: number
    member_no: string
    card_no: string
    last_name: string
    first_name: string
    last_name_kana: string
    first_name_kana: string
    sex: SexT
    birthday: string
    phone: string
    email: string
    postal_code: string
    prefecture: string
    city: string
    street: string
    apartment: string
    employee_flag: EmployeeFlagT
    member_kind: MemberKindT
    member_type: MemberTypeT
  }
}
export type UpdateMemberRawResponse = FetchMemberRawResponse & {
  status: 0 | 100 | 201 // 正常終了=0, トークン有効期限切れ=100, パラメータチェックエラー時:201
}

export interface CreateMemberParams {
  lastName: string // 性
  firstName: string // 名
  lastNameKana: string // 姓(カナ)
  firstNameKana: string // 名(カナ)
  // nickname: string // ニックネーム
  gender: GenderT // 'other' | 'male' | 'female' // 性別(0:不明 1:男性 2:女性)
  birthday: string // 誕生日(YYYY-MM-DD)
  phone: string // 電話(ハイフンなし)
  // email: string // メールアドレス
  postalCode: string // 郵便番号
  prefecture: string // 都道府県
  city: string // 市区町村
  street: string // 町名番地
  apartment: string // ビルマンション
  password: string // 会員サイトログインパスワード(未入力の場合更新しない)
}

export interface UpdateMemberParams {
  lastName: string // 性
  firstName: string // 名
  lastNameKana: string // 姓(カナ)
  firstNameKana: string // 名(カナ)
  // nickname: string // ニックネーム
  gender: GenderT // 'other' | 'male' | 'female' // 性別(0:不明 1:男性 2:女性)
  birthday: string // 誕生日(YYYY-MM-DD)
  phone: string // 電話(ハイフンなし)
  // email: string // メールアドレス // 変更不可なので削ぎ落とす
  postalCode: string // 郵便番号
  prefecture: string // 都道府県
  city: string // 市区町村
  street: string // 町名番地
  apartment: string // ビルマンション
}

export interface Member {
  id: number
  memberNo: string
  cardNo: string
  lastName: string
  firstName: string
  lastNameKana: string
  firstNameKana: string
  sex: SexT
  birthday: string
  phone: string
  email: string
  postalCode: string
  prefecture: string
  city: string
  street: string
  apartment: string
  employeeFlag: EmployeeFlagT
  memberKind: MemberKindT
  memberType: MemberTypeT
  // extra
  gender: GenderT
  isGuest: boolean
  isVip: boolean
  isEmployee: boolean
}

export interface OnetimeCodeRawResponse {
  status: 0 | 100 // 正常終了=0, トークン有効期限切れ=100
  onetimecode: string
  expires_at: string // "YYYY/MM/DD HH:MM:SS"
}

export interface OnetimeCodeInfo {
  onetimeCode: string
  expiresAt: string // "YYYY/MM/DD HH:MM:SS"
}

export interface FetchMemberRankRawResponse {
  status: number
  member_rank: {
    member_type: number // 会員種別:(0:仮会員 1:本会員 2:板カード 3:旧板カード会員)
    member_kind: number // 会員タイプ:(1:一般 2:VIP)
    from: string
    to: string
    rankup_visit: number
    rankup_price: number
    rankup_point: number
    visit_num: number
    price: number
    point: number
    need_visit: number
    need_price: number
    need_point: number
    next_rank: number
    upgrade_distance: string // '3カ月'
    downgrade_distance: string // '1年2カ月',
  }
}

export interface MemberRankInfo {
  memberType: number
  memberKind: number
  from: string
  to: string
  rankupVisit: number
  rankupPrice: number
  rankupPoint: number
  visitNum: number
  price: number
  point: number
  needVisit: number
  needPrice: number
  needPoint: number
  nextRank: number
  upgradeDistance: string // '3カ月'
  downgradeDistance: string // '1年2カ月',
}

export interface MemberRankInfoResponse {
  status: number
  memberRank: MemberRankInfo
}

export function toSex(gender: GenderT | undefined): SexT {
  switch (gender) {
    case 'other':
      return 0
    case 'male':
      return 1
    case 'female':
      return 2
    default:
      return 0
  }
}

export function toGender(sex: SexT | undefined): GenderT {
  switch (sex) {
    case 0:
      return 'other'
    case 1:
      return 'male'
    case 2:
      return 'female'
    default:
      // return undefined
      return 'other'
  }
}

export function toGenderFromAccountCenter(sex: number | undefined): GenderT {
  switch (sex) {
    case 0:
      return 'male'
    case 1:
      return 'female'
    case 2 || 3:
      return 'other'
    default:
      return 'other'
  }
}

export function toGenderLabel(genderOrSex: GenderT | SexT | undefined): string {
  switch (genderOrSex) {
    case 'other':
    case 0:
      return 'その他'
    case 'male':
    case 1:
      return '男性'
    case 'female':
    case 2:
      return '女性'
    default:
      // return undefined
      return 'その他'
  }
}

export function fromMemberRawResponse(
  rawResponse: FetchMemberRawResponse
): Member {
  const payloadMember = rawResponse.member
  const member: Member = {
    id: payloadMember.id,
    memberNo: payloadMember.member_no,
    cardNo: payloadMember.card_no,
    lastName: payloadMember.last_name,
    firstName: payloadMember.first_name,
    lastNameKana: payloadMember.last_name_kana,
    firstNameKana: payloadMember.first_name_kana,
    sex: payloadMember.sex,
    birthday: payloadMember.birthday,
    phone: payloadMember.phone,
    email: payloadMember.email,
    postalCode: payloadMember.postal_code,
    prefecture: payloadMember.prefecture,
    city: payloadMember.city,
    street: payloadMember.street,
    apartment: payloadMember.apartment,
    employeeFlag: payloadMember.employee_flag,
    memberKind: payloadMember.member_kind,
    memberType: payloadMember.member_type,
    // extra
    gender: toGender(payloadMember.sex),
    isGuest: payloadMember.member_kind === 0,
    isVip: payloadMember.member_type === 2,
    isEmployee: payloadMember.employee_flag === 1,
  }

  return member
}

export function fromOnetimeCodeRawResponse(
  rawResponse: OnetimeCodeRawResponse
): OnetimeCodeInfo {
  return {
    onetimeCode: rawResponse.onetimecode,
    expiresAt: rawResponse.expires_at,
  }
}

export function fromMemberRankRawResponse(
  rawResponse: FetchMemberRankRawResponse
): MemberRankInfoResponse {
  return {
    status: rawResponse.status,
    memberRank: {
      memberType: rawResponse.member_rank.member_type,
      memberKind: rawResponse.member_rank.member_kind,
      from: rawResponse.member_rank.from,
      to: rawResponse.member_rank.to,
      rankupVisit: rawResponse.member_rank.rankup_visit,
      rankupPrice: rawResponse.member_rank.rankup_price,
      rankupPoint: rawResponse.member_rank.rankup_point,
      visitNum: rawResponse.member_rank.visit_num,
      price: rawResponse.member_rank.price,
      point: rawResponse.member_rank.point,
      needVisit: rawResponse.member_rank.need_visit,
      needPrice: rawResponse.member_rank.need_price,
      needPoint: rawResponse.member_rank.need_point,
      nextRank: rawResponse.member_rank.next_rank,
      upgradeDistance: rawResponse.member_rank.upgrade_distance,
      downgradeDistance: rawResponse.member_rank.downgrade_distance,
    },
  }
}

export function fromCreateMemberParams(params: CreateMemberParams) {
  return {
    last_name: params.lastName,
    first_name: params.firstName,
    last_name_kana: params.lastNameKana,
    first_name_kana: params.firstNameKana,
    // nickname: params.nickname,
    sex: toSex(params.gender),
    birthday: params.birthday,
    phone: params.phone,
    // email: params.email, // Only for CREATE
    postal_code: params.postalCode,
    prefecture: params.prefecture,
    city: params.city,
    street: params.street,
    apartment: params.apartment,
    password: params.password, // Only for CREATE
  }
}

export function fromUpdateMemberParams(params: UpdateMemberParams) {
  return {
    last_name: params.lastName,
    first_name: params.firstName,
    last_name_kana: params.lastNameKana,
    first_name_kana: params.firstNameKana,
    // nickname: params.nickname,
    sex: toSex(params.gender),
    birthday: params.birthday,
    phone: params.phone,
    // email: params.email,
    postal_code: params.postalCode,
    prefecture: params.prefecture,
    city: params.city,
    street: params.street,
    apartment: params.apartment,
    // password:	password,
  }
}
