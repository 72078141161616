import React, { CSSProperties } from 'react'

import { MemberRank, MemberRankInfo } from '../../models/member'
import { ExpirationPointInfo } from '../../models/point'
import { Panel } from '../atoms/Panel'
import { AccountBarcode } from '../molecules/AccountBarcode'
import { PointCardHistorySummary } from '../molecules/PointCardHistorySummary'
import { PointCardPointsInfo } from '../molecules/PointCardPointsInfo'
import { GuideMessage } from '../molecules/GuideMessage'

interface PointCardCardPanelProps {
  memberRank: MemberRank
  isEmployee: boolean
  points: number | undefined
  expirationPointInfos?: ExpirationPointInfo[]
  onetimeCode: string | undefined
  onetimeCodeExpiresAt: string | undefined
  cardNo: string
  memberRankInfo: MemberRankInfo | undefined
  onOnetimeCodeExpire: () => void
}

const styles: { [name: string]: CSSProperties } = {
  panel: {
    position: 'relative',
    paddingTop: '16px',
  },
  cardEdge: {
    borderRadius: '16px',
    overflow: 'hidden',
  },
}

export function PointCardCardPanel({
  memberRank,
  isEmployee,
  points,
  expirationPointInfos,
  onetimeCode,
  onetimeCodeExpiresAt,
  cardNo,
  memberRankInfo,
  onOnetimeCodeExpire,
}: PointCardCardPanelProps) {
  return (
    <Panel style={styles.panel}>
      <div style={styles.cardEdge}>
        <PointCardPointsInfo
          memberRank={memberRank}
          isEmployee={isEmployee}
          points={points}
        />
        <PointCardHistorySummary
          headerText="期限付きポイント(最大3件表示。詳細はご利用明細で)"
          memberRank={memberRank}
          isEmployee={isEmployee}
          expirationPointInfos={expirationPointInfos}
        />
        <AccountBarcode
          onetimeCode={onetimeCode}
          onetimeCodeExpiresAt={onetimeCodeExpiresAt}
          cardNo={cardNo}
          paddingTop="8px"
          paddingBottom="12px"
          onOnetimeCodeExpire={onOnetimeCodeExpire}
        />
        <GuideMessage memberRank={memberRank} memberRankInfo={memberRankInfo} />
      </div>
    </Panel>
  )
}
